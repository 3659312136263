import React, { useRef, useState } from "react";
import styled, { css } from 'styled-components';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
// import required modules
import { Pagination, Navigation } from "swiper";
import Galeria1 from '../../img/galeria/PageAnastasia.png';
import Galeria2 from '../../img/galeria/E-learning.png';
import Galeria3 from '../../img/galeria/SistemaMensajeria.png';
import Galeria4 from '../../img/galeria/CartelSpeakerTalent.png';
const ContenedorStyled= styled.div`
  width: 100%;
  height: 520px;
  .imgGaleria{
    width: 373px;
    height: 480px;
    max-height: 480px;
  }
`
const Galeria=()=>{
  return (
    <ContenedorStyled>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        slidesPerGroup={1}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide> <a href="./archives/Portfolio.pdf" title="Portfolio" target="_blank"><img src={Galeria1} className="imgGaleria"/></a></SwiperSlide>
        <SwiperSlide><a href="./archives/Portfolio.pdf" title="Portfolio" target="_blank"><img src={Galeria2} className="imgGaleria"/></a></SwiperSlide>
        <SwiperSlide><a href="./archives/Portfolio.pdf" title="Portfolio" target="_blank"><img src={Galeria3} className="imgGaleria"/></a></SwiperSlide>
        <SwiperSlide><a href="./archives/Portfolio.pdf" title="Portfolio" target="_blank"><img src={Galeria4} className="imgGaleria"/></a></SwiperSlide>
      </Swiper>
      </ContenedorStyled>
  )
};export default Galeria;