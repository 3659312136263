import styled, { css } from 'styled-components';
import '../../App.css';


const MenuStyled= styled.section `
@keyframes example {
  from {bottom: -300px; }
  to {bottom: 0;}
}
position:fixed;
bottom:0;
font-family: 'Roboto Condensed';
font-style: normal;
font-weight: 400;
color: #FFFFFF;
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-around;
z-index: 100;
animation-name: example;
animation-duration: 3s;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
@media (min-width: 768px){
  flex-direction:column;
  align-items: flex-end;
  bottom: 50%;
  right: 1px;
  width: auto;

  @keyframes example {
  from {right: -300px; }
  to {right: 1px;}
}
}
@media (min-width: 1200px){
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  top:5px;  
}
a:hover{
  .img_buttom{
    border: 3px solid var(--rosa);
  }
}
.img_buttom{
  width:80px;
  height: 80px;
  border-radius: 50%;
  border:1px solid var(--primary);
  background-color: #FFF;
  margin-bottom: 0.3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90% 90%;

  @media (min-width: 1200px){
  margin:0.5rem;
  }
}
.uno{
  background-image: url("/img/Perfil.svg");
}
.dos{
  background-image: url("/img/Portfolio.svg");
}
.tres{
  background-image: url("/img/Subir.svg");
}
.base_menu{
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: var(--secondary);
  z-index: -1;
  bottom: 0;
  @media (min-width: 768px){
    visibility: hidden;
  }
}
  `
const Menu=()=>(
  <MenuStyled > 
    <a href="#experiencias"><div className="img_buttom uno"></div></a>
    <a href="./archives/Portfolio.pdf" title="Portfolio" target="_blank"><div className="img_buttom dos"></div></a>
    <a href="#" title="subir"><div className="img_buttom tres"></div></a>
    <div className="base_menu"></div>
  
</MenuStyled>
);
export default Menu;