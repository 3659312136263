import './styles.css';
const Etiquetas= ()=>{
  return(
    <section className="etiquetas">
      <span className="categoria">FrontEnd</span>
      <span className="fecha">2022</span> 
    </section>
  )
};
export default Etiquetas;

