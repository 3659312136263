import './styles.css';
import Tarjeta from '../Tarjeta';
const Contenedor= ({filtrado}) =>{  /*como quiero el objeto que tiene que se llama portfoliio, tengo que poner las llaves para desconstruir filtrado*/ 
console.log("filtrado",filtrado);
  return (
      <section className="contenedor">        
        {
          
          filtrado.portfolio?.map((item, index) =>(
            <Tarjeta
              key={index}
              titulo={item.titulo}
              subtitulo={item.subtitulo}/>
            )
          )
        }
      </section>
  )
};
export default Contenedor;