import './styles.css';
import Pie from '../../components/Pie';
import CabeceraDetalle from '../../components/CabeceraDetalle'
import Etiquetas from '../../components/Etiquetas';
import Contenido from '../../components/ContenidoDetalle';
import PaginacionDetalle from '../../components/PaginacionDetalle';
import { useState, useEffect } from 'react';
const Portfolio= () =>{
  const [datosPF, setdatosPf]=useState([{}]);
  useEffect(() =>{
    fetch('./Portfolio.json')
    .then(respuesta => {
      return respuesta.json()})
    .then(data =>{
      setdatosPf(data)
    })
  },[])
  return (
    <>
    <div className="main">
      <CabeceraDetalle/>
      <Etiquetas/>
      <Contenido/>
      <PaginacionDetalle/>
      <div className="ondapie">
        <svg  viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M375 0H0V20.866C0 30.5262 37.8989 32.0718 66.8218 37.4815C95.7447 42.8912 129.654 29.3669 155.585 28.5941C181.516 27.8213 221.908 54.097 263.298 37.4815C296.41 24.1891 347.906 37.3527 375 42.8912V0Z" fill="#FFF"/>
        </svg>
      </div>
      
    </div>
    <Pie colorfondo={"#2B2D42"}/>
    </>
  )
};
export default Portfolio;