import './styles.css';

const Filtro= () =>{
  return (
    <section className="filtro">
      <div className="filtro_btn">Web</div>
      <div className="filtro_btn">D. Gráfico</div>
      <div className="filtro_btn">Todos</div>
    </section>   
  )
};
export default Filtro;