import './styles.css';
import Portada from '../../components/Portada/index';
import Menu from '../../components/Menu/index';
import Seccion from '../../components/Seccion/index';
import Pie from '../../components/Pie/index';
import { useState, useEffect } from 'react';


function Principal() {
  const [jDatos, setjDatos]=useState([{}]);
  useEffect (()=> {
    fetch('./Datos.json')
    .then(respuesta => {
      return respuesta.json()})
    .then(data => {
      setjDatos(data)
    })
  },[])
  return (
    <div className="App"> 
      <Menu/>
      <Portada/>
      <div className="experiencias" id="experiencias">
      {
        jDatos.seccion && (
          jDatos.seccion.map((item, index)=> (
              <Seccion 
              key={index}
              tipo={item.tipo}
              titulo={item.titulo}
              texto={item.texto}
              fondo={item.fondo}
              fondoonda={item.fondoonda}
              coloronda={item.coloronda}
              imagen={item.imagen}/>
            )
          )
        )
      }
      </div>
      <Pie colorfondo={"#2B2D42"}/>
    </div>
  );
}
export default Principal;