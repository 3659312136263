import './App.css';
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";

import Principal from './pages/Principal';
import Portfolio from './pages/Portfolio';
import Detalle from './pages/Detalle';

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Principal />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/detalle" element={<Detalle/>} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;