import './styles.css';
import Filtro from '../Filtro/index';
const Cabecera= () =>{
  return (
      <section className="portada">
        <div className="cabecera">
        <a href="/" title="volver"><div className="volver"></div></a>
          <h1 className="h1">Portfolio</h1>
        </div>
        <div className="onda">
          <svg  viewBox="0 0 375 43"  xmlns="http://www.w3.org/2000/svg">
            <path d="M375 0H0V20.866C0 30.5262 37.8989 32.0718 66.8218 37.4815C95.7447 42.8912 129.654 29.3669 155.585 28.5941C181.516 27.8213 221.908 54.097 263.298 37.4815C296.41 24.1891 347.906 37.3527 375 42.8912V0Z"/>
          </svg>
        </div>
        <div className="grupo">
          <div className="descripcion">
            <p className="p">En mi trayectoria laboral he creado distintas interfaces con diseño accesible y responsive, adaptable a diferentes dispositivos, encargándome en ocasiones en el diseño U/X U/I para mejorar la experiencia de usuario.</p>
            <p className="p">También he maquetado cursos e-learning de distintas materias, encargándome de la creación de contenidos como, manuales, videotutoriales, ejercicios... </p>
          </div>
          <div className="img_decoracion">
            <img src="/img/Portfolio.svg"/>
          </div>
        </div>
        <Filtro/>
      </section>
  )
};
export default Cabecera;