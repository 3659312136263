import './styles.css';
import Composicion from '../../img/Composicion_1.png';
const ContenidoDetalle= ()=>{
  return(
    <section className="contenidoDetalle">
          <p className="rol">Análisis, diseño de U/X U/I, maquetación y programación final.</p>
          <a href="#" className="enlace" title="enlace">http; fjfjfjf</a>
          <img className="img composicion" src={Composicion} alt=""/>
          <p className="objetivo">Análisis, diseño de U/X U/I, maquetación y programación final.</p>
          <p className="reto">Análisis, diseño de U/X U/I, maquetación y programación final.</p>
          <p className="sobre">Análisis, diseño de U/X U/I, maquetación y programación final.</p>
          <img className="img bocetos" src={Composicion} alt=""/>
          <img className="img mockups" src={Composicion} alt=""/>
      </section>
  )
};
export default ContenidoDetalle;