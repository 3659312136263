import styled, { css } from 'styled-components';
import '../../App.css';
import Galeria from '../Galeria/index';
import ButtonStyle from '../Button/index';

//color de onda bajo es el complementario 2 dark #FB8500

const SeccionStyled= styled.section `
font-family: 'Roboto Condensed';
font-style: normal;
font-weight: 400;
color: #FFFFFF;
@media (min-width: 768px){
  display: flex;
  flex-direction: column;
  align-items: center;
}
${props => props.titulo === 'Conocimientos y Habilidades' && css`
  color:#000;
`}
background-color:${props => props.fondo};
@media (min-width: 768px){
  width:${props => props.tipo==="galeria" || props.tipo==="contacto" ? "100%" : "50%"};
  
}
@media (min-width: 1200px){
  width:${props => props.tipo==="habilidades" || props.tipo==="galeria" || props.tipo==="contacto" ? "100%" : "33.3%"};
}
  .dosondas{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width:100%;
    .onda:nth-child(2){
      display: none;
      @media (min-width:768px){
      display: flex;
      }
      @media (min-width:1200px){
      display: none;
      }
      svg{
        path{
          @media (min-width:768px){
            fill:#FFF;
          }
        }
      }
    }
  }
  .onda{
    width: 100%;
    background-color:${props => props.fondoonda};/* este es el color de la onda (abajo) */
    @media (min-width:768px){
      width:${props => props.tipo==="galeria" ? "50%" : "100%"};
    }
    @media (min-width:1200px){
      width:${props => props.tipo==="galeria" ? "100%" : "100%"};
    }
    svg{
      margin-top:-1px;
      width: 100%;
      path{
        width:100%;
        fill:${props => props.coloroonda};
        @media(min-width:768px){
          fill:${props => props.tipo==="web" ? "#FFF" :"" || props.tipo==="grafico" ? "#FFF" :"" || props.tipo==="formacion" ? "#FB8500" :"" || props.tipo==="galeria" ? "#219EBC" :"" || props.tipo==="habilidades" ? "#2B2D42" :"" || props.tipo==="contacto" ? "#FFF" :"" };
        }
        @media(min-width:1200px){
          fill:${props => props.tipo==="habilidades" ? "#ef233c":"" || props.tipo==="formacion" ? "#FFF" :"" || props.tipo==="galeria" ? "#FFF" :""};
        }
      }
    }
  }
.cabecera{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin:0 1rem;
  padding-top: 1rem;

  h2{
    /*white-space: pre;*/
    text-align: left;
    font-size: 2.2rem;
    font-weight: 400;
  }
}

.contenido{
  padding: 1rem;
  .texto{
    font-size: 1.5rem;
    line-height: 29px;
    @media (min-width: 768px){
      min-height: 14.5rem;
      ul{
        column-count:2;
      }
    }
    @media (min-width: 1200px){
      ul{
        column-count:3;
      }
    }
  }
  .enlace{
    font-size: 1.2rem;
    line-height: 23px;
    text-align: center;
    color:#FFFFFF;
    display: inherit;
  }
}
a.sinlinea{
    text-decoration: none;
  }

`
const Seccion=({tipo, titulo, texto, fondo, fondoonda, coloronda, imagen})=>(
  <SeccionStyled  tipo={tipo} titulo={titulo} texto={texto} fondo={fondo} fondoonda={fondoonda} coloronda={coloronda}> 
  {console.log(`/img/${imagen}`)}
{console.log('fondo', fondo)}
  {tipo === "galeria" ?(
  <>
  <div className="dosondas">
    <div className="onda">
    <svg viewBox="0 0 375 43"  fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1.00529,0,0,1,-1,-11)">
        <path d="M375,0L0,0L0,21.5C0,31.16 37.899,32.072 66.822,37.481C95.745,42.891 129.654,29.367 155.585,28.594C181.516,27.821 221.908,54.097 263.298,37.481C296.41,24.189 374.035,30.912 375,21.5C375.034,21.17 375,0 375,0Z" fill={coloronda}/>
    </g>
        </svg>
    </div>
    <div className="onda">
    <svg viewBox="0 0 375 43"  fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1.00529,0,0,1,-1,-11)">
        <path d="M375,0L0,0L0,21.5C0,31.16 37.899,32.072 66.822,37.481C95.745,42.891 129.654,29.367 155.585,28.594C181.516,27.821 221.908,54.097 263.298,37.481C296.41,24.189 374.035,30.912 375,21.5C375.034,21.17 375,0 375,0Z" fill={coloronda}/>
    </g>
        </svg>
    </div>
  </div>
  <div className="cabecera">
    <h2>{titulo}</h2>
  </div>
  <Galeria/>
  <div className="btncentral">
    <a href="./archives/Portfolio.pdf" title="Portfolio" target="_blank" className="sinlinea"><ButtonStyle tipo={"primary"} texto="Ver Portfolio"/></a>
    <br/>
    <a href="./archives/CV.pdf" title="Portfolio" target="_blank" className="sinlinea"><ButtonStyle tipo={"primary"} texto="Ver CV"/></a>
  </div>
</>
  ):(
    <>
      <div className="onda">
        <svg viewBox="0 2 375 40"  fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1.00529,0,0,1,-1,-1)">
          <path d="M375,0L0,0L0,21.5C0,31.16 37.899,32.072 66.822,37.481C95.745,42.891 129.654,29.367 155.585,28.594C181.516,27.821 221.908,54.097 263.298,37.481C296.41,24.189 374.035,30.912 375,21.5C375.034,21.17 375,0 375,0Z" fill={coloronda}/>
        </g>
        </svg>
      </div>
    <div className="cabecera">
    <h2>{titulo}</h2>
    <img src={`/img/${imagen}`} alt=""/>
  </div>
    <div className="contenido">
    {titulo==="Contacto" ? (
      <a href="https://www.linkedin.com/in/evatux/" target="_blank" className="enlace">Ver Perfil en Linkedin</a>
    ): (
      <p className="texto"  dangerouslySetInnerHTML={{__html: texto}}></p>
    )}
  </div>
    
  </>
  )}
  
</SeccionStyled>
);
export default Seccion;