import styled, { css } from 'styled-components';
import '../../App.css';
import Perfil from '../../img/Evatux.png'

const PortadaStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /*height: 100vh;*/
  @media (min-width: 768px){
    height: auto;
  }
  background-color: #FFF;
  
  .contenedor{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--primary); 
    width: 100%;
    margin:0;
    .cabecera{
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media (min-width: 768px){
        justify-content: center;
        margin-top:1.5rem;
      }
      width: 100%;
      /*Media cueries cambiar el tamaño*/
      .imagen{
        margin:0.5rem 0.5rem 0 0.5rem;
      }
      .titulo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0.5rem;
        .h1{
          font-family: 'Gochi Hand';
          font-style: normal;
          font-weight: 100;
          font-size: 2.5rem;
          line-height: auto;
          color:#FFF; 
          @media (min-width: 768px){
            width: 100%;
            font-size: 4rem;
          }   
        }
      }
    }
  }

.onda{
  margin: -1px;
  width: 100%;
  svg {
    
    width: 100%;
    
  } 
}
.ondaBajoportada{
  position: absolute;
  width: 99.8%;
  @media (min-width: 768px){
    /*position: relative;*/
    display: none;
  }
  bottom: 0;
  background-color: var(--complementary2Dark);
  svg{
    margin: -1px;
    width: 100%;
  }
}
  .presentacion{
    position:relative;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.5rem;
    margin:0 1rem;
    padding-left: 1rem;
    text-indent: 2rem;
    &:before{
      content:open-quote;
      font-family: 'Roboto Condensed';
      font-style: normal;
      color: var(--primary);
      font-size: 3.5rem;
      position:absolute;
      top:-3px;
      left: -10px;
    }
    &:after{
      position:relative;
      content:close-quote;
      font-family: 'Roboto Condensed';
      font-style: normal;
      color: var(--primary);
      top:-3px;
    }

    @media (min-width: 768px){
      min-height: auto;
      margin: 1rem 5rem;
    }
  }
  `;

const Portada= () => (
  <PortadaStyle>
    <div className="contenedor">
      <div className="cabecera" id="cabecera">
        <div className="imagen">
          <img className="img" src={Perfil}/>
        </div>
        <div className="titulo"><h1 className="h1">Hola, soy Evatux</h1></div>
    </div>
    </div>
    <div className="onda">
      <svg  viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M375 0H0V20.866C0 30.5262 37.8989 32.0718 66.8218 37.4815C95.7447 42.8912 129.654 29.3669 155.585 28.5941C181.516 27.8213 221.908 54.097 263.298 37.4815C296.41 24.1891 347.906 37.3527 375 42.8912V0Z" fill="#EF233C"/>
      </svg>
    </div>
    <p className="presentacion">
      Mi nombre es Eva María Fernández Ponti, soy Front End y Diseñadora Gráfica. También he trabajado en formación, tanto presencial como e-learning.</p>
      {/*<div className="ondaBajoportada">
      <svg  viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M375 0H0V20.866C0 30.5262 37.8989 32.0718 66.8218 37.4815C95.7447 42.8912 129.654 29.3669 155.585 28.5941C181.516 27.8213 221.908 54.097 263.298 37.4815C296.41 24.1891 347.906 37.3527 375 42.8912V0Z" fill="#FFF"/>
      </svg>
</div>*/}
  </PortadaStyle>
);
export default Portada

