import './styles.css';
const Paginacion= () =>{
  return (
    <section className="paginacion">
      <span>>></span>
      <span>></span>
      <span className="numerador_item">
        1
      </span>
      <span className="numerador_item active">
        2
      </span>
      <span className="numerador_item">
        3
      </span>
      <span className="numerador_item">
        4
      </span>
      <span>></span>
      <span>>></span>
    </section>
  )
};
export default Paginacion;