import './styles.css';
const Tarjeta = ({key,titulo, subtitulo})=>{
  console.log("titulo", titulo);
  return(
    <div className="contenedor_item">
          <div className="etiqueta">
            <h2 className="h2">{titulo}</h2>
          <span className="subtitulo">{subtitulo}</span>
          </div>
        </div>
  )
};
export default Tarjeta;