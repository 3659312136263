import styled, { css } from 'styled-components';
import '../../App.css';
// Para cargar se p
//<ButtonStyle tipo={"primary"} texto="hola"/>
//<ButtonStyle tipo={"secondary"} texto="hlll"/>
const ButtonStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 47px;
  border-radius: 8px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color:#FFF;
  @media (min-width: 768px){
    margin: 1rem;
  }
 
  ${props => props.tipo=== "primary" && css`
    background: var(--degradado);
    border:2px solid var(--rosa);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    &:hover{
      /*background-color: var(--primaryDark);*/
      background:#FFF;
      color:var(--rosa);
    }
  `}
  ${props => props.tipo=== "secondary" && css`
    background-color: var(--secondary);
    border:2px solid var(--secondary);
    &:hover{
      background-color: var(--grayLight);
      color:var(--secondary);
    }
  `}
`;
const Button = ({tipo, texto}) => (
  <ButtonStyle tipo={tipo} >{texto}</ButtonStyle>
);
export default Button;

