import styled, { css } from 'styled-components';
import '../../App.css';
const PieStyled= styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color:${props => props.colorfondo};
  padding-top: 0.5rem;

  .contenido{
    padding: 0 1rem;
    margin: 0  0 6rem 0;
    @media (min-width: 768px){
      margin: 0  0 1rem 0;
  }
  .texto{
    font-size: 0.9rem;
    color: var(--grayLight);
    margin: 0.1rem;
  }
}
`
const Pie=({colorfondo}) =>(
  <PieStyled colorfondo={colorfondo}>
    <div className="contenido">
      <p className="texto">Copyright © 2023 evatux.com</p><p className="texto">Todos los derechos reservados</p>
    </div>
  </PieStyled>
);
export default Pie;
