import './styles.css';
const CabeceraDetalle= () =>{
  return (
    <section className="portadaDetalle">
    <a href="/" title="volver"><div className="volver"></div></a>
    <div className="contenedorDetalle">
      <h1 className="h1">
        <span className="titulo">Página Personal</span>
        <span className="subtitulo">Anastasia Kondratieva</span>
      </h1>
    </div>
  </section>
  )
};
export default CabeceraDetalle;